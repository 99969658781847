(function() {
  var AWEActivitiesPage, AWELightsPage, AWENerdalizePage, AWEPage, AWEProjects, AWESite, AWESupportingCupsPage, CustomEvent, Slides, base, base1, base2, base3, base4, easing, throttleEvent,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty,
    indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

  AWEPage = (function() {
    function AWEPage(el1, id1) {
      var el, j, len, ref;
      this.el = el1;
      this.id = id1;
      this.updateSize = bind(this.updateSize, this);
      if (!this.color) {
        this.color = '#292E38';
      }
      window.addEventListener('throttledResize', this.updateSize);
      this.iframeEls = this.el.querySelectorAll('iframe');
      ref = this.el.querySelectorAll('.slides');
      for (j = 0, len = ref.length; j < len; j++) {
        el = ref[j];
        new Slides(el);
      }
      this;
    }

    AWEPage.prototype.deconstruct = function() {
      window.removeEventListener('throttledResize', this.updateSize);
      return this;
    };


    /*
    	preload: () ->
    		els = @el.querySelectorAll('[data-preload]')
    		urls = []
    		
    		for el in els
    			if el.src
    				urls.push(el.src)
    			else if el.style.backgroundImage
    				urls.push(el.style.backgroundImage.replace(/url\((.*)\)/g, "$1"))
    			else
    				continue
    		
    		return Promise.resolve()# if urls.length is 0
    		
    		promise = new Promise( (resolve) ->
    			preloadimages(urls, resolve)
    		)
    		
    		promise
     */

    AWEPage.prototype.animateIn = function() {
      var el, j, len, ref;
      this.updateSize();
      this.el.classList.remove('loading');
      if (this.color) {
        ref = document.querySelectorAll('.site-title-header path');
        for (j = 0, len = ref.length; j < len; j++) {
          el = ref[j];
          el.style.fill = this.color;
        }
      }
      return Promise.resolve();
    };

    AWEPage.prototype.animateOut = function() {
      if (this.animatingIn) {
        return Promise.reject();
      }
      return Promise.resolve('');
    };

    AWEPage.prototype.updateSize = function() {
      var el, j, len, ref, results;
      ref = this.iframeEls;
      results = [];
      for (j = 0, len = ref.length; j < len; j++) {
        el = ref[j];
        if (el.width && el.height) {
          results.push(el.style.height = el.height / el.width * el.offsetWidth + 'px');
        } else {
          results.push(void 0);
        }
      }
      return results;
    };

    return AWEPage;

  })();

  Slides = (function() {
    function Slides(el1) {
      var el, fn, i, j, len, ref;
      this.el = el1;
      this.showSlide = bind(this.showSlide, this);
      this.stopAutoplay = bind(this.stopAutoplay, this);
      this.startAutoplay = bind(this.startAutoplay, this);
      this.navEls = this.el.querySelectorAll('.slides-nav > button');
      this.contentEls = this.el.querySelectorAll('.slides-content > *');
      this.currentSlide = 0;
      this.autoplayInterval = null;
      ref = this.navEls;
      fn = (function(_this) {
        return function(i) {
          if (el.classList.contains('active')) {
            _this.currentSlide = i;
          }
          return el.addEventListener('click', function() {
            _this.stopAutoplay();
            return _this.showSlide(i);
          });
        };
      })(this);
      for (i = j = 0, len = ref.length; j < len; i = ++j) {
        el = ref[i];
        fn(i);
      }
      this.startAutoplay();
      this;
    }

    Slides.prototype.startAutoplay = function() {
      if (this.autoplayInterval) {
        return;
      }
      return this.autoplayInterval = setInterval((function(_this) {
        return function() {
          var nextSlide;
          nextSlide = _this.currentSlide + 1;
          if (nextSlide >= _this.contentEls.length) {
            nextSlide = 0;
          }
          return _this.showSlide(nextSlide);
        };
      })(this), 5000);
    };

    Slides.prototype.stopAutoplay = function() {
      if (!this.autoplayInterval) {
        return;
      }
      clearInterval(this.autoplayInterval);
      return this.autoplayInterval = null;
    };

    Slides.prototype.showSlide = function(i) {
      if (i === this.currentSlide) {
        return;
      }
      this.navEls[this.currentSlide].classList.remove('active');
      this.contentEls[this.currentSlide].classList.remove('active');
      this.currentSlide = i;
      this.navEls[this.currentSlide].classList.add('active');
      return this.contentEls[this.currentSlide].classList.add('active');
    };

    return Slides;

  })();

  window.awe || (window.awe = {});

  window.awe.AWEPage = AWEPage;

  AWEActivitiesPage = (function(superClass) {
    extend(AWEActivitiesPage, superClass);

    function AWEActivitiesPage() {
      this.color = 'rgba(255, 255, 255, 1)';
      AWEActivitiesPage.__super__.constructor.apply(this, arguments);
    }

    return AWEActivitiesPage;

  })(awe.AWEPage);

  window.awe || (window.awe = {});

  (base = window.awe).pages || (base.pages = {});

  window.awe.pages['activities'] = AWEActivitiesPage;

  AWELightsPage = (function(superClass) {
    extend(AWELightsPage, superClass);

    function AWELightsPage() {
      this.color = 'white';
      AWELightsPage.__super__.constructor.apply(this, arguments);
    }

    return AWELightsPage;

  })(awe.AWEPage);

  window.awe || (window.awe = {});

  (base1 = window.awe).pages || (base1.pages = {});

  window.awe.pages['lights'] = AWELightsPage;

  AWENerdalizePage = (function(superClass) {
    extend(AWENerdalizePage, superClass);

    function AWENerdalizePage() {
      var headerAnimationContainerEl, observer;
      headerAnimationContainerEl = document.querySelector('.header-animation-container');
      observer = new IntersectionObserver((function(_this) {
        return function(entries) {
          if (entries[0].isIntersecting) {
            return headerAnimationContainerEl.classList.remove('paused');
          } else {
            return headerAnimationContainerEl.classList.add('paused');
          }
        };
      })(this));
      observer.observe(headerAnimationContainerEl);
      AWENerdalizePage.__super__.constructor.apply(this, arguments);
    }

    return AWENerdalizePage;

  })(awe.AWEPage);

  window.awe || (window.awe = {});

  (base2 = window.awe).pages || (base2.pages = {});

  window.awe.pages['nerdalize'] = AWENerdalizePage;

  AWESupportingCupsPage = (function(superClass) {
    extend(AWESupportingCupsPage, superClass);

    function AWESupportingCupsPage() {
      this.color = '#463F36';
      AWESupportingCupsPage.__super__.constructor.apply(this, arguments);
    }

    return AWESupportingCupsPage;

  })(awe.AWEPage);

  window.awe || (window.awe = {});

  (base3 = window.awe).pages || (base3.pages = {});

  window.awe.pages['supporting-cups'] = AWESupportingCupsPage;

  AWEProjects = (function(superClass) {
    extend(AWEProjects, superClass);

    function AWEProjects(el1) {
      var el, j, k, len, len1, ref, ref1;
      this.el = el1;
      this.updateSize = bind(this.updateSize, this);
      this.color = 'rgba(57, 77, 98, 0.5)';
      AWEProjects.__super__.constructor.apply(this, arguments);
      this.projectsEl = this.el.querySelector('.projects-list');
      this.projectsEls = this.projectsEl.querySelectorAll('.project');
      ref = this.projectsEls;
      for (j = 0, len = ref.length; j < len; j++) {
        el = ref[j];
        el.style.opacity = 0;
      }
      ref1 = this.projectsEls;
      for (k = 0, len1 = ref1.length; k < len1; k++) {
        el = ref1[k];
        el.addEventListener('touchstart', function() {
          return this.classList.add('active');
        });
        el.addEventListener('touchmove', function() {
          return this.classList.remove('active');
        });
        el.addEventListener('touchend', function() {
          return setTimeout((function(_this) {
            return function() {
              return _this.classList.remove('active');
            };
          })(this), 600);
        });
      }
      window.addEventListener('throttledResize', this.updateSize);
      window.addEventListener("pageshow", this.updateSize);
      this.updateSize();
      this;
    }

    AWEProjects.prototype.animateIn = function() {
      var duration, el, i, j, k, projects, promises, ref, ref1, ref2, transformProperty, u, v;
      AWEProjects.__super__.animateIn.apply(this, arguments);
      if (this["in"]) {
        return;
      }
      this["in"] = true;
      this.animatingIn = true;
      duration = 800;
      projects = [];
      for (v = j = ref = this.rows.length - 1; ref <= 0 ? j <= 0 : j >= 0; v = ref <= 0 ? ++j : --j) {
        for (u = k = ref1 = this.rows[v].length - 1; ref1 <= 0 ? k <= 0 : k >= 0; u = ref1 <= 0 ? ++k : --k) {
          if (this.rows[v][u] && (ref2 = this.rows[v][u], indexOf.call(projects, ref2) < 0)) {
            projects.push(this.rows[v][u]);
          }
        }
      }
      transformProperty = Velocity.CSS.Names.prefixCheck('transform')[0];
      promises = (function() {
        var l, len, ref3, results;
        ref3 = projects.reverse();
        results = [];
        for (i = l = 0, len = ref3.length; l < len; i = ++l) {
          el = ref3[i];
          results.push(Velocity({
            e: el,
            p: {
              scale: [1, 0.9],
              opacity: [1, 0]
            },
            o: {
              delay: i * 100 + 200,
              duration: duration,
              easing: [300, 20],
              complete: function(els) {
                return els[0].style[transformProperty] = '';
              }
            }
          }));
        }
        return results;
      })();
      return Promise.all(promises).then((function(_this) {
        return function() {
          return _this.animatingIn = false;
        };
      })(this));
    };

    AWEProjects.prototype.animateOut = function() {
      var duration, el, i, j, k, projects, promises, ref, ref1, ref2, u, v;
      this["in"] = false;
      duration = 400;
      projects = [];
      for (v = j = ref = this.rows.length - 1; ref <= 0 ? j <= 0 : j >= 0; v = ref <= 0 ? ++j : --j) {
        for (u = k = ref1 = this.rows[v].length - 1; ref1 <= 0 ? k <= 0 : k >= 0; u = ref1 <= 0 ? ++k : --k) {
          if (ref2 = this.rows[v][u], indexOf.call(projects, ref2) < 0) {
            projects.push(this.rows[v][u]);
          }
        }
      }
      promises = (function() {
        var l, len, ref3, results;
        ref3 = projects.reverse();
        results = [];
        for (i = l = 0, len = ref3.length; l < len; i = ++l) {
          el = ref3[i];
          results.push(Velocity({
            e: el,
            p: {
              scale: 0
            },
            o: {
              delay: i * 100,
              duration: duration,
              easing: 'ease'
            }
          }));
        }
        return results;
      })();
      promises.push(Velocity({
        e: this.el,
        p: {
          opacity: 0
        },
        o: {
          delay: 0,
          duration: 400,
          easing: 'ease'
        }
      }));
      return Promise.all(promises).then((function(_this) {
        return function() {
          if (_this.animatingIn) {
            return Promise.reject();
          }
        };
      })(this));
    };

    AWEProjects.prototype.updateSize = function() {
      var a, baseX, baseY, checkHeight, checkWidth, free, height, i, j, k, l, large, lastFixed, len, len1, len2, len3, m, maxLarge, n, newRow, o, p, position, positionXOffset, positionYOffset, projectEl, projectHeight, projectHeightPX, projectMarginPX, projectWidth, projectWidthPX, q, r, ref, ref1, ref10, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, results, results1, t, toFill, u, v, width, x, y;
      projectWidthPX = this.projectsEl.querySelector('.project:not([data-width])').offsetWidth;
      projectMarginPX = parseInt(window.getComputedStyle(this.projectsEl.querySelector('.project')).marginBottom, 10);
      projectWidth = (projectWidthPX + projectMarginPX) / window.innerWidth * 100;
      this.columnCount = Math.floor(this.projectsEl.offsetWidth / (projectWidthPX + projectMarginPX));
      this.columnCount || (this.columnCount = 1);
      if (this.previousColumnCount === this.columnCount) {
        return;
      }
      this.previousColumnCount = this.columnCount;
      projectHeightPX = this.projectsEl.querySelector('.project:not([data-height])').offsetHeight;
      projectHeight = (projectHeightPX + projectMarginPX) / window.innerWidth * 100;
      x = 0;
      y = 0;
      this.fits = 0;
      if (this.columnCount === 1) {
        this.rows = [];
      } else {
        this.rows = [[]];
      }
      large = 0;
      maxLarge = 3;
      ref = this.projectsEls;
      for (t = j = 0, len = ref.length; j < len; t = ++j) {
        projectEl = ref[t];
        if (this.columnCount === 1) {
          height = Math.round(projectEl.offsetHeight / projectHeightPX);
          projectEl.style.top = 0;
          for (i = k = 1, ref1 = height; 1 <= ref1 ? k <= ref1 : k >= ref1; i = 1 <= ref1 ? ++k : --k) {
            this.rows.push([projectEl]);
          }
          continue;
        }
        width = Math.round(projectEl.offsetWidth / projectWidthPX);
        width = Math.min(width, this.columnCount);
        height = Math.round(projectEl.offsetHeight / projectHeightPX);
        if (width > 1 && height > 1) {
          large++;
        }
        checkWidth = width;
        checkHeight = height;
        positionXOffset = 0;
        positionYOffset = 0;
        if (large >= maxLarge) {
          if (width < this.columnCount) {
            checkWidth = width + 1;
          } else {
            checkHeight = height + 1;
            positionYOffset = 1;
          }
          large = 0;
          maxLarge += 1;
        }
        baseX = x;
        baseY = y;
        position = false;
        i = 0;
        while (!position && i < 100) {
          position = this.fit(checkWidth, checkHeight, baseX, baseY);
          if (baseX >= this.columnCount) {
            baseY++;
            baseX = 0;
          } else {
            baseX++;
          }
          i++;
        }
        if (!position) {
          continue;
        }
        position[0] += positionXOffset;
        position[1] += positionYOffset;
        for (u = l = ref2 = position[0], ref3 = position[0] + width - 1; ref2 <= ref3 ? l <= ref3 : l >= ref3; u = ref2 <= ref3 ? ++l : --l) {
          for (v = m = ref4 = position[1], ref5 = position[1] + height - 1; ref4 <= ref5 ? m <= ref5 : m >= ref5; v = ref4 <= ref5 ? ++m : --m) {
            this.rows[v][u] = projectEl;
          }
        }
        projectEl.style.left = position[0] * projectWidth + 'vw';
        projectEl.style.top = position[1] * projectHeight + 'vw';
      }
      if (this.columnCount === 1) {
        this.projectsEl.style.height = '';
      } else {
        this.projectsEl.style.height = this.rows.length * projectHeight + 'vw';
      }
      lastFixed = -1;
      free = [];
      toFill = [];
      ref6 = this.rows[this.rows.length - 1];
      for (i = n = 0, len1 = ref6.length; n < len1; i = ++n) {
        projectEl = ref6[i];
        if (!projectEl) {
          continue;
        }
        height = Math.round(projectEl.offsetHeight / projectHeightPX);
        if (height === 1) {
          free.push(i);
          continue;
        }
        if (lastFixed < i - 1) {
          toFill.push((function() {
            results = [];
            for (var o = ref7 = i - 1, ref8 = lastFixed + 1; ref7 <= ref8 ? o <= ref8 : o >= ref8; ref7 <= ref8 ? o++ : o--){ results.push(o); }
            return results;
          }).apply(this));
        }
        lastFixed = i;
      }
      if (toFill.length > 0 && free.length > 0) {
        if (this.columnCount - 1 !== lastFixed) {
          toFill.push((function() {
            results1 = [];
            for (var p = ref9 = this.columnCount - 1, ref10 = lastFixed + 1; ref9 <= ref10 ? p <= ref10 : p >= ref10; ref9 <= ref10 ? p++ : p--){ results1.push(p); }
            return results1;
          }).apply(this));
        }
        for (q = 0, len2 = toFill.length; q < len2; q++) {
          a = toFill[q];
          if (a.length !== free.length) {
            continue;
          }
          toFill = [a];
          break;
        }
        toFill.sort(function(a, b) {
          return a.length - b.length;
        });
        toFill = toFill.reduce(function(a, b) {
          return a.concat(b);
        }, []);
        newRow = this.rows[this.rows.length - 1].slice();
        console.log(toFill);
        for (i = r = 0, len3 = free.length; r < len3; i = ++r) {
          x = free[i];
          if (toFill[i] === void 0) {
            break;
          }
          this.rows[this.rows.length - 1][x].style.left = toFill[i] * projectWidth + 'vw';
          newRow[toFill[i]] = this.rows[this.rows.length - 1][x];
        }
        return this.rows[this.rows.length - 1] = newRow;
      }
    };

    AWEProjects.prototype.fit = function(width, height, x, y) {
      var base4, j, k, ref, ref1, ref2, ref3, u, v;
      this.fits++;
      for (v = j = ref = y, ref1 = y + height - 1; ref <= ref1 ? j <= ref1 : j >= ref1; v = ref <= ref1 ? ++j : --j) {
        (base4 = this.rows)[v] || (base4[v] = []);
        for (u = k = ref2 = x, ref3 = x + width - 1; ref2 <= ref3 ? k <= ref3 : k >= ref3; u = ref2 <= ref3 ? ++k : --k) {
          if (u >= this.columnCount) {
            return false;
          }
          if (this.rows[v][u]) {
            return false;
          }
          if (width > 1 && height > 1 && v < 1 && u < 1) {
            return false;
          }
        }
      }
      return [x, y];
    };

    return AWEProjects;

  })(awe.AWEPage);

  window.awe || (window.awe = {});

  (base4 = window.awe).pages || (base4.pages = {});

  window.awe.pages['projects'] = AWEProjects;

  AWESite = (function() {
    function AWESite() {
      var currentPageEl;
      if (!CSS.supports('mix-blend-mode', 'overlay')) {
        document.body.classList.add('bland-header');
      }
      this.scrollOffsets = [];
      this.mainContentSelector = '.main';
      this.htmlEl = document.querySelector('html');
      this.titleEl = document.querySelector('title');
      currentPageEl = document.querySelector(this.mainContentSelector);
      this.showPage(currentPageEl);
      if (window.MonsterViewer) {
        this.monsterViewer || (this.monsterViewer = new MonsterViewer());
      }
      if ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
        document.body.classList.add('touch');
      }
      document.body.addEventListener('click', (function(_this) {
        return function(e) {
          var target;
          target = e.target;
          while (target.tagName) {
            if (target.tagName.toLowerCase() !== 'a') {
              target = target.parentNode;
              continue;
            }
            if (e.ctrlKey || e.shiftKey || e.metaKey || (e.button && e.button === 1)) {
              return;
            }
            if (target.href.indexOf('mailto') === 0) {
              return;
            }
            e.preventDefault();
            document.body.classList.add('loading');
            setTimeout(function() {
              return window.location.href = target.href;
            }, 200);
            return;
          }
        };
      })(this));
    }

    AWESite.prototype.showProjects = function() {
      this.projects.el.classList.remove('out');
      return this.showPage(this.projects.el, 'projects', this.projects);
    };

    AWESite.prototype.showPage = function(el, title, controller) {
      var id, page;
      id = el.children[0].id;
      page = controller || new (awe.pages[id] || awe.AWEPage)(el, id);
      this.currentPage = page;
      return setTimeout((function(_this) {
        return function() {
          return _this.pageDidLoad();
        };
      })(this), 500);
    };

    AWESite.prototype.pageDidLoad = function() {
      var ref;
      if ((ref = this.currentPage) != null) {
        ref.animateIn();
      }
      return document.body.classList.remove('loading');
    };

    return AWESite;

  })();

  document.addEventListener("DOMContentLoaded", function() {
    return window.aweSite = new AWESite();
  });

  window.addEventListener("pageshow", function() {
    return window.aweSite.pageDidLoad();
  });

  window.addEventListener("pagehide", function() {
    return document.body.classList.add('loading');
  });

  if (typeof CustomEvent !== 'function') {
    CustomEvent = function(event, params) {
      var evt;
      params = params || {
        bubbles: false,
        cancelable: false,
        detail: void 0
      };
      evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
      return evt;
    };
    CustomEvent.prototype = window.CustomEvent.prototype;
    window.CustomEvent = CustomEvent;
  }

  throttleEvent = function(type, name, obj) {
    var func, running;
    if (obj == null) {
      obj = window;
    }
    running = false;
    func = function() {
      if (running) {
        return;
      }
      running = true;
      return requestAnimationFrame(function() {
        obj.dispatchEvent(new CustomEvent(name));
        return running = false;
      });
    };
    return obj.addEventListener(type, func);
  };

  easing = {
    easeInOutExpo: function(x, t, b, c, d) {
      if (t === 0) {
        return b;
      }
      if (t === d) {
        return b + c;
      }
      if ((t /= d / 2) < 1) {
        return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
      }
      return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
    }
  };

  window.awe.easing = easing;

  throttleEvent('resize', 'throttledResize');

  throttleEvent('scroll', 'throttledScroll');

}).call(this);
